@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Futura;
  src: url('../fonts/Futura.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Myriad;
  src: url('../fonts/Myriad-pro-bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Myriad;
  src: url('../fonts/Myriad-pro.otf');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  height: 100%;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input:focus,
input:focus-visible {
  outline: none;
}

.wrapper {
  border-radius: unset !important;
  z-index: 1 !important;
  cursor: pointer !important;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 100px;
  background-color: rgb(0 0 0 / 90%);
}

.modal-wrapper {
  width: 600px;
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 100px;
}

.modal {
  background: white;
  border-radius: 15px;
  padding: 15px;
}

.modal-body {
  padding-top: 1px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0063e1;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader.small {
  width: 30px;
  height: 30px;
  border-width: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  appearance: none;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar:vertical {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
